/* .property-list {
  margin-top: 20px;
} */

table {
  margin-bottom: 0px !important;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.table-dark th {
  background-color: #343a40;
  color: white;
}

.btn-sm {
  margin-bottom: 5px;
}

.pagination {
  margin-top: 10px;
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.pagination .page-item .page-link {
  color: #f9a201;
}

.pagination .page-item .page-link:hover {
  color: #d88a00;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #f9a201;
  border-color: #f9a201;
}

.icon-align{
  text-align: center;
}