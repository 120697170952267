.container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header {
  background-color: #f9a201;
  color: white;
  cursor: pointer;
}

.card-header:hover {
  background-color: #d48201;
}

.card-body {
  padding: 20px;
}

.form-control {
  margin-bottom: 10px;
}

.form-check {
  margin-top: 10px;
}

.btn-primary {
  background-color: #f9a201;
  border: none;
}

.btn-primary:hover {
  background-color: #d48201;
}

.mt-3 {
  margin-top: 1rem;
}
