table {
  margin-bottom: 0px !important;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.table-dark th {
  background-color: #343a40;
  color: white;
}

.manager-usuarios .title-container {
  margin-bottom: 20px;
}

.manager-usuarios .form-control {
  margin-bottom: 15px;
}

.manager-usuarios .table-responsive {
  margin-top: 20px;
}

.manager-usuarios .pagination .page-link {
  color: #f9a201;
}

.manager-usuarios .pagination .page-link:hover {
  background-color: #f9a201;
  color: #fff;
}

.manager-usuarios .pagination .page-item.active .page-link {
  background-color: #f9a201;
  border-color: #f9a201;
}

.manager-usuarios .btn-primary {
  background-color: #f9a201;
  border-color: #f9a201;
}

.manager-usuarios .btn-primary:hover {
  background-color: #d88a00;
  border-color: #d88a00;
}

.manager-usuarios .btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.manager-usuarios .btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.manager-usuarios .btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
}

.manager-usuarios .btn-warning:hover {
  background-color: #e0a800;
  border-color: #d39e00;
}

.manager-usuarios .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.manager-usuarios .btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.manager-usuarios .btn-dark {
  background-color: #343a40;
  border-color: #343a40;
}

.manager-usuarios .btn-dark:hover {
  background-color: #23272b;
  border-color: #1d2124;
}
