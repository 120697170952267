.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 96vh;
  background: url("../../../public/fundo.jpeg") no-repeat center center fixed;
  background-size: cover;
}

.card {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

@supports (
  (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))
) {
  .card {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(
      255,
      255,
      255,
      0.6
    ); /* Ajuste a opacidade para o efeito desejado */
  }
}

.card-body {
  padding: 2rem;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.form-label {
  font-weight: bold;
}

h2 {
  font-weight: bold;
  color: #333;
}

img {
  border-radius: 50%;
}
