.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #343a40;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
}

.sidebar .nav-link {
  color: white;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s;
}

.sidebar .nav-link:hover {
  background-color: #495057;
  color: white;
}

.sidebar .nav-link.active {
  background-color: #f9a201;
  color: white;
}

.sidebar h2 {
  color: #fff;
  margin-bottom: 20px;
}

.sidebar img {
  border-radius: 50%;
  margin-bottom: 20px;
}

.toggle-button {
  display: none;
}

@media (max-width: 767px) {
  .toggle-button {
    display: block;
    background-color: #f9a201;
    border: none;
    color: white;
    font-size: 24px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 1001;
  }

  .sidebar {
    transform: translateX(-250px);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar.open {
    transform: translateX(0);
  }
}

@media (min-width: 768px) {
  .toggle-button {
    display: none;
  }

  .sidebar {
    transform: translateX(0);
  }
}

.main-content {
  transition: margin-left 0.3s ease-in-out;
  margin-left: 250px;
  padding: 20px;
}

@media (max-width: 767px) {
  .main-content {
    margin-left: 0;
  }

  .sidebar.open + .main-content {
    margin-left: 250px;
  }
}
