.modal-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modal-backdrop.show {
  display: block;
  opacity: 1;
}

.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
  transform: scale(0.9);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.modal-backdrop.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal-content {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.modal-header,
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.modal-header .close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.modal-title {
  margin: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
